import React from "react";
import { defaultProps } from "@blocknote/core";
import { createReactBlockSpec } from "@blocknote/react";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import theme from "../../theme";
import { VoiceSelectionMenu } from "./VoiceSelectionMenu";

export const availableVoices = [
  {
    title: "🇺🇸 English Male",
    value: "iP95p4xoKVk53GoZ742B",
    icon: RecordVoiceOverIcon,
    color: theme.palette.voices.englishMaleUS,
  },
  {
    title: "🇺🇸 English Female",
    value: "XrExE9yKIg1WjnnlVkGX",
    icon: RecordVoiceOverIcon,
    color: theme.palette.voices.englishFemaleUS,
  },
  {
    title: "🇬🇧 English Male",
    value: "RSmgoqO778pcU4L1nBGw",
    icon: RecordVoiceOverIcon,
    color: theme.palette.voices.englishMaleGB,
  },
  {
    title: "🇸🇪 Swedish Male",
    value: "x0u3EW21dbrORJzOq1m9",
    icon: RecordVoiceOverIcon,
    color: theme.palette.voices.swedishMale,
  },
  {
    title: "🇸🇪 Swedish Female",
    value: "4xkUqaR9MYOJHoaC1Nak",
    icon: RecordVoiceOverIcon,
    color: theme.palette.voices.swedishFemale,
  },
];

const VoiceBlockContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 1,
  borderRadius: theme.shape.borderRadius,
  minHeight: 30,
  padding: theme.spacing(0.5),
  backgroundColor: theme.palette.background.white,
}));

const VoiceBlockContent = ({ block, editor, contentRef }) => {
  const handleSelectVoiceId = (voiceId) => {
    editor.updateBlock(block, {
      type: "paragraph",
      props: { voice_id: voiceId },
    });
  };

  return (
    <VoiceBlockContainer>
      <Box sx={{ alignSelf: "flex-start", ml: "2px", mt: "2px", mr: "2px" }}>
        <VoiceSelectionMenu
          handleSelectVoiceId={handleSelectVoiceId}
          currentVoiceId={block.props.voice_id}
          availableVoices={availableVoices}
        />
      </Box>
      <Box
        sx={{ flexGrow: 1 }}
        ref={contentRef}
        className="voice-block-content"
      />
    </VoiceBlockContainer>
  );
};

export const VoiceBlock = createReactBlockSpec(
  {
    type: "paragraph",
    propSchema: {
      textAlignment: defaultProps.textAlignment,
      textColor: defaultProps.textColor,
      voice_id: {
        default: "default-voice-id",
        values: availableVoices.map((voice) => voice.value),
      },
    },
    content: "inline",
  },
  {
    render: (props) => <VoiceBlockContent {...props} />,
  }
);
