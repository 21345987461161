import theme from "../../theme";

export const customTheme = {
  colors: {
    editor: {
      text: theme.palette.text.primary,
      background: theme.palette.background.secondary,
    },
    menu: {},
    tooltip: {},
    hovered: {},
    selected: {},
    disabled: {},
  },
  fontSize: "10px",
  fontFamily: "Helvetica Neue, sans-serif",
};
