import { Extension } from "@tiptap/core";

// Link to "paywalled" custom extension examples: https://github.com/TypeCellOS/BlockNote/blob/3b5af24042596839ae2e640075c27aa3a952a5d0/examples/08-extensions/01-tiptap-arrow-conversion/App.tsx

export const PreventLinksExtension = Extension.create({
  name: "preventLinks",

  onCreate() {
    // Watch for any changes to the document
    this.editor.on("update", ({ editor }) => {
      const { state, view } = editor;

      // Find all link marks in the document
      state.doc.descendants((node, pos) => {
        if (node.marks?.some((mark) => mark.type.name === "link")) {
          // Create a transaction to remove the link mark
          const tr = state.tr
            .removeMark(pos, pos + node.nodeSize, state.schema.marks.link)
            .setMeta("preventAutolink", true);

          // Dispatch the transaction
          view.dispatch(tr);
        }
      });
    });
  },
});
