import { useState, useEffect, useMemo } from "react";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { PreventLinksExtension } from "./Extensions";
import { Box } from "@mui/material";
import {
  BlockNoteSchema,
  defaultStyleSpecs,
  defaultInlineContentSpecs,
  filterSuggestionItems,
  BlockNoteEditor,
} from "@blocknote/core";
import { VoiceBlock } from "./VoiceBlock";
import {
  SuggestionMenuController,
  DragHandleMenu,
  RemoveBlockItem,
  SideMenu,
  SideMenuController,
} from "@blocknote/react";
import { PauseBlock, insertPauseBlock } from "./PauseBlock";
import { ELEVEN_LABS_DEFAULT_VOICE_ID } from "../../constants";
import DefaultVoiceSelector from "./DefaultVoiceSelector";
import theme from "../../theme";
import { customTheme } from "./styles";
import "./styles.css";

function getAudioBlockEditorSchema() {
  return BlockNoteSchema.create({
    blockSpecs: {
      // enable the default blocks if desired
      // paragraph: defaultBlockSpecs.paragraph,

      // Add your own custom blocks:
      paragraph: VoiceBlock,
      pause: PauseBlock,
    },
    inlineContentSpecs: {
      // enable the default inline content if desired
      ...defaultInlineContentSpecs,
      // Add your own custom inline content:
      // customInlineContent: CustomInlineContent,
    },
    styleSpecs: {
      // enable the default styles if desired
      ...defaultStyleSpecs,

      // Add your own custom styles:
      // customStyle: CustomStyle
    },
  });
}

export default function AudioBlockEditor({
  handleBlocksChange,
  shouldEmptyAudioBlocks,
  setShouldEmptyAudioBlocks,
}) {
  const [defaultVoiceId, setDefaultVoiceId] = useState(
    ELEVEN_LABS_DEFAULT_VOICE_ID
  );

  const handleDefaultVoiceIdChange = (voiceId) => {
    setDefaultVoiceId(voiceId);

    const selection = editor.getSelection();
    const selectedBlocks =
      selection !== undefined
        ? selection.blocks
        : [editor.getTextCursorPosition().block];

    selectedBlocks.forEach((block) => {
      if (block.type === "paragraph") {
        editor.updateBlock(block, {
          props: { voice_id: voiceId },
        });
      }
    });
  };

  const handleEditorChange = () => {
    // Update all the dummy default voice ids for all blocks
    const voiceIdPropToChange = "default-voice-id";

    editor.forEachBlock((block) => {
      if (block.type === "paragraph") {
        if (block.props.voice_id === voiceIdPropToChange) {
          editor.updateBlock(block, {
            props: { voice_id: defaultVoiceId },
          });
        }
      }
    });
    handleBlocksChange(editor.document);
  };

  const handleKeyboardInput = (event) => {
    if (event.key === "Tab") {
      // Disable indentation
      event.preventDefault();
    }
  };

  const handleSelectionChange = (event) => {
    // Not used. Just here to show that it exists.
  };

  useEffect(() => {
    if (shouldEmptyAudioBlocks) {
      editor.removeBlocks(editor.document);
      setShouldEmptyAudioBlocks(false);
    }
  }, [shouldEmptyAudioBlocks]);

  const editor = useMemo(() => {
    const schema = getAudioBlockEditorSchema();
    return BlockNoteEditor.create({
      schema,
      trailingBlock: true,
      initialContent: [
        {
          type: "paragraph",
          props: {
            voice_id: defaultVoiceId,
          },
        },
      ],
      _tiptapOptions: {
        extensions: [PreventLinksExtension],
      },
    });
  }, []);

  return (
    <Box
      sx={{
        background: theme.palette.background.secondary,
        borderRadius: 2,
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <DefaultVoiceSelector
        handleSelectVoiceId={handleDefaultVoiceIdChange}
        currentVoiceId={defaultVoiceId}
      />
      <BlockNoteView
        editor={editor}
        slashMenu={false}
        formattingToolbar={false}
        sideMenu={false}
        emojiPicker={false}
        onChange={handleEditorChange}
        theme={customTheme}
        onKeyDownCapture={handleKeyboardInput}
        onSelectionChange={handleSelectionChange}
        data-theming-css-demo
      >
        {/* Replaces the default Slash Menu. */}
        <SuggestionMenuController
          triggerCharacter={"/"}
          getItems={async (query) =>
            filterSuggestionItems([insertPauseBlock(editor)], query)
          }
        />
        {/* Side menu */}
        <SideMenuController
          sideMenu={(props) => (
            <SideMenu
              {...props}
              dragHandleMenu={(props) => (
                <DragHandleMenu {...props}>
                  <RemoveBlockItem {...props}>Delete</RemoveBlockItem>
                </DragHandleMenu>
              )}
            />
          )}
        />
      </BlockNoteView>
    </Box>
  );
}
