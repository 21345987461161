import React, { useState, useEffect, useContext } from "react";
import AddEpisodeForm from "./AddEpisodeForm";
import {
  listEpisodes,
  addEpisode,
  deleteEpisode,
  updateEpisode,
} from "../api/episode";
import {
  CardContent,
  Typography,
  Skeleton,
  Box,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import FeedContext from "../contexts/FeedContext";
import UserContext from "../contexts/UserContext";
import { StyledCard } from "../atoms/StyledCard";
import ExistingEpisode from "./ExistingEpisode";
import UsageCircularProgress from "./UsageCircularProgress";
import useEpisodePolling from "../hooks/useEpisodePolling";

function AddEpisodeInstructions() {
  return (
    <Box sx={{ mb: "2rem" }}>
      <Typography variant="h3" sx={{ mb: "1rem" }}>
        Add new episode
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Typography variant="body2">
          <strong>1. Create your episode</strong>
          <br />
          Enter a name and your text, then click "Generate Audio". Your episode
          will be automatically created and added to your list.
        </Typography>

        <Typography variant="body2">
          <strong>2. Fine-tune your audio</strong>
          <br />
          Use the audio editor to: • Fix pronunciations • Modify text • Change
          voices or language • Adjust individual paragraphs
        </Typography>

        <Typography variant="body2">
          <strong>3. Publish and share</strong>
          <br />
          Toggle the publish switch when ready. Your episode will instantly
          become available in your podcast feed. Your podcast is already hosted
          on our platform - no external hosting needed!
        </Typography>

        <Box sx={{ width: "100%" }}>
          <Typography variant="body2">
            <strong>Usage tracking</strong>
            <br />
            The progress bar below shows your current usage. It updates whenever
            you generate audio for new or existing episodes, based on the number
            of letters processed.
          </Typography>
          <Box sx={{ ml: "0.5rem", mt: "0.5rem" }}>
            <UsageCircularProgress />
          </Box>
        </Box>
        <Box sx={{ bgcolor: "background.secondary", p: 2, borderRadius: 1 }}>
          <Typography variant="body2" sx={{ fontStyle: "italic" }}>
            💡 Pro tip: Start with short episodes to experiment with different
            voices and settings. Your podcast remains private until you choose
            to list it on platforms like Apple Podcasts or Spotify.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function ExistingEpisodesInstructions({ hasNoEpisodes }) {
  return (
    <Box sx={{ mb: "2rem" }}>
      <Typography variant="h3" sx={{ mt: "4rem", mb: "1rem" }}>
        Existing episodes
      </Typography>
      <Typography variant="body2">
        <strong>Edit and publish</strong>
        <br />
        Fine-tune your episodes anytime: adjust text, modify audio settings, or
        update publication status. Each episode appears here and syncs
        automatically with your podcast feed when published. Use the audio
        editor to perfect your episodes - adjust voice settings, fix
        pronunciations, or fine-tune pause lengths even after publication.
      </Typography>
      {hasNoEpisodes && (
        <Typography
          variant="body2"
          sx={{ mt: "2.5rem", mb: 0, fontWeight: "bold" }}
        >
          Podcast episodes that you create will appear here!
        </Typography>
      )}
    </Box>
  );
}

export default function EpisodeList() {
  const { loadUser } = useContext(UserContext);
  const { activeFeed } = useContext(FeedContext);
  const [episodes, setEpisodes] = useState([]);
  const [addingEpisode, setAddingEpisode] = useState(false);
  const [loadingEpisodes, setLoadingEpisodes] = useState(false);
  const [successTriggerAddEpisode, setSuccessTriggerAddEpisode] =
    useState(false);
  const [deletingEpisodeUuid, setDeletingEpisodeUuid] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pollingEpisodeUuid, setPollingEpisodeUuid] = useState(null);

  useEpisodePolling(pollingEpisodeUuid, activeFeed, () => {
    fetchEpisodes();
    setPollingEpisodeUuid(null);
  });

  const fetchEpisodes = () => {
    if (episodes.length === 0) {
      // Show loading skeleton while fetching episodes
      setLoadingEpisodes(true);
    }
    listEpisodes(activeFeed.uuid)
      .then((responseData) => {
        setEpisodes(responseData);
        setAddingEpisode(false);
        setLoadingEpisodes(false);
        setDeletingEpisodeUuid(null);
      })
      .catch((error) => {
        setError(true);
        setErrorMessage(error.message || "Failed to fetch episodes.");
        setAddingEpisode(false);
        setLoadingEpisodes(false);
        setDeletingEpisodeUuid(null);
      });
  };

  const handleDelete = (episode_uuid) => {
    setDeletingEpisodeUuid(episode_uuid);
    deleteEpisode(activeFeed.uuid, episode_uuid)
      .then(() => {
        fetchEpisodes();
      })
      .catch((error) => {
        setError(true);
        setErrorMessage(error.message || "Failed to delete episode.");
      });
  };

  const handleSubmitEpisode = (formData) => {
    setAddingEpisode(true);
    addEpisode(activeFeed.uuid, formData)
      .then((data) => {
        setPollingEpisodeUuid(data.uuid); // Start polling for this episode
        fetchEpisodes();
        loadUser();
        setSuccessTriggerAddEpisode(true);
      })
      .catch((error) => {
        setError(true);
        setErrorMessage(error.message || "Failed to add episode.");
        fetchEpisodes();
        loadUser();
      });
  };

  const handleUpdateEpisode = (episode, formData) => {
    updateEpisode(activeFeed.uuid, episode.uuid, formData)
      .then(() => {
        fetchEpisodes();
      })
      .catch((error) => {
        setError(true);
        setErrorMessage(error.message || "Failed to update episode.");
      });
  };

  const handleUpdateEpisodeStatus = (episode, event) => {
    const newStatus = event.target.checked ? "PUBLISHED" : "DRAFT";

    updateEpisode(activeFeed.uuid, episode.uuid, {
      status: newStatus,
    })
      .then(() => {
        fetchEpisodes();
      })
      .catch((error) => {
        setError(true);
        setErrorMessage(error.message || "Failed to update episode.");
        fetchEpisodes();
      });
  };

  const handleCloseError = () => {
    setError(false);
    setErrorMessage("");
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessTriggerAddEpisode(false);
  };

  useEffect(() => {
    if (activeFeed === null) {
      return;
    }
    fetchEpisodes();
  }, [activeFeed]);

  return (
    <StyledCard>
      <CardContent sx={{ mb: "-1rem" }}>
        <AddEpisodeInstructions />
        <Box sx={{ mb: "1rem" }}>
          <AddEpisodeForm
            onSubmit={handleSubmitEpisode}
            addingEpisode={addingEpisode}
            successTriggerAddEpisode={successTriggerAddEpisode}
          />
        </Box>

        <ExistingEpisodesInstructions
          hasNoEpisodes={!loadingEpisodes && episodes.length === 0}
        />

        {loadingEpisodes && (
          <Box sx={{ mb: "1rem" }}>
            <StyledCard
              sx={{ borderWidth: "2px", borderColor: "secondary.light" }}
            >
              <CardContent>
                <Skeleton variant="text" width={210} height={30} />
                <Skeleton variant="text" width="100%" height={30} />
                <Skeleton variant="text" width="100%" height={30} />
                <Skeleton variant="text" width="70%" height={30} />
              </CardContent>
            </StyledCard>
          </Box>
        )}

        {episodes.map((episode) => (
          <Box sx={{ mb: "1rem" }} key={episode.uuid}>
            <ExistingEpisode
              episode={episode}
              onDelete={handleDelete}
              deletingEpisodeUuid={deletingEpisodeUuid}
              handleUpdateEpisode={handleUpdateEpisode}
              handleUpdateEpisodeStatus={handleUpdateEpisodeStatus}
              fetchEpisodes={fetchEpisodes}
              setPollingEpisodeUuid={setPollingEpisodeUuid}
            />
          </Box>
        ))}
      </CardContent>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          {errorMessage} Please try again.
        </Alert>
      </Snackbar>
      <Snackbar
        open={successTriggerAddEpisode}
        autoHideDuration={14000}
        onClose={handleCloseSuccess}
      >
        <Alert severity="success" onClose={handleCloseSuccess}>
          <AlertTitle>Successfully triggered audio generation!</AlertTitle>
          Processing time: A few seconds for short texts, up to a few minutes
          for longer ones.
          <br />
          Feel free to close this page - we'll keep working on it.
        </Alert>
      </Snackbar>
    </StyledCard>
  );
}
