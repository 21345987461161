import { createReactBlockSpec } from "@blocknote/react";
import { styled } from "@mui/material/styles";
import { Box, Slider, Typography } from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";

const PauseContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
  backgroundColor: theme.palette.background.white,
  borderRadius: theme.shape.borderRadius,
  minHeight: "30px",
  width: "100%",
}));

const IconWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export const insertPauseBlock = (editor) => ({
  title: "Add pause after paragraph",
  onItemClick: () => {
    // Block that the text cursor is currently in.
    const currentBlock = editor.getTextCursorPosition().block;

    // New block we want to insert.
    const pauseBlock = {
      type: "pause",
      content: "",
      props: {
        duration: 0.6,
      },
    };

    // Inserting the new block after the current one.
    editor.insertBlocks([pauseBlock], currentBlock, "after");
  },
  aliases: ["wait", "delay", "break"],
  group: "Insert a new block",
  icon: <TimerIcon />,
});

export const PauseBlock = createReactBlockSpec(
  {
    type: "pause",
    propSchema: {
      duration: {
        default: 0.6,
        values: Array.from({ length: 101 }, (_, i) => i / 10), // 0 to 10 in 0.1 steps
      },
    },
    content: "none", // This block doesn't need text content
  },
  {
    render: (props) => {
      return (
        <PauseContainer>
          <IconWrapper>
            <TimerIcon sx={{ fontSize: 20, color: "text.secondary" }} />
          </IconWrapper>
          <Slider
            value={props.block.props.duration}
            min={0}
            max={10}
            step={0.1}
            valueLabelDisplay="auto"
            size="small"
            sx={{ flexGrow: 1 }}
            onChange={(_, newValue) => {
              props.editor.updateBlock(props.block, {
                type: "pause",
                props: { duration: newValue },
              });
            }}
          />
          <Typography sx={{ flexShrink: 0 }} variant="body2" color="secondary">
            {props.block.props.duration}s pause
          </Typography>
        </PauseContainer>
      );
    },
  }
);
