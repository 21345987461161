import { Box, Typography } from "@mui/material";
import { MIN_CHARS_PER_EPISODE } from "../../constants";

export default function AudioBlockEditorWrapper({
  children,
  textTooShort,
  textTooLong,
  textLength,
  maxCharsToAdd,
}) {
  return (
    <Box>
      <Box sx={{ width: "100%", mb: 1 }}>{children}</Box>
      <Typography
        variant="body2"
        color={textTooLong ? "error.main" : "text.secondary"}
        sx={{ ml: 2 }}
      >
        <>{`${textLength} of max ${maxCharsToAdd} characters`}</>
        <br />
        <>
          {textTooShort
            ? `Write at least ${MIN_CHARS_PER_EPISODE} characters to create episode`
            : textTooLong && `Too many characters to create episode`}
        </>
      </Typography>
    </Box>
  );
}
