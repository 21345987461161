import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Menu, MenuItem, MenuList } from "@mui/material";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: 0,
  height: "18px",
  width: "18px",
  userSelect: "none",
  cursor: "pointer",
});

const StyledIcon = styled(Box)(({ theme }) => ({
  '&[data-voice-icon-type="iP95p4xoKVk53GoZ742B"]': {
    color: theme.palette.voices.englishMaleUS,
  },
  '&[data-voice-icon-type="XrExE9yKIg1WjnnlVkGX"]': {
    color: theme.palette.voices.englishFemaleUS,
  },
  '&[data-voice-icon-type="RSmgoqO778pcU4L1nBGw"]': {
    color: theme.palette.voices.englishMaleGB,
  },
  '&[data-voice-icon-type="x0u3EW21dbrORJzOq1m9"]': {
    color: theme.palette.voices.swedishMale,
  },
  '&[data-voice-icon-type="4xkUqaR9MYOJHoaC1Nak"]': {
    color: theme.palette.voices.swedishFemale,
  },
}));

export const VoiceSelectionMenu = ({
  handleSelectVoiceId,
  currentVoiceId,
  availableVoices,
}) => {
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const isMenuOpen = Boolean(menuAnchorElement);

  const handleOpenMenu = (event) => {
    setMenuAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorElement(null);
  };

  return (
    <Box>
      <IconWrapper
        contentEditable={false}
        onClick={handleOpenMenu}
        aria-controls={isMenuOpen ? "voice-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
      >
        <StyledIcon
          component={RecordVoiceOverIcon}
          data-voice-icon-type={currentVoiceId}
          sx={{ fontSize: 20 }}
        />
      </IconWrapper>
      <Menu
        id="voice-menu"
        anchorEl={menuAnchorElement}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        disableRipple
      >
        <MenuList
          subheader={
            <Box sx={{ px: 2, py: 1, typography: "subtitle2" }}>Voice</Box>
          }
        >
          {availableVoices.map((type) => {
            const ItemIcon = type.icon;
            return (
              <MenuItem
                key={type.value}
                onClick={() => {
                  handleSelectVoiceId(type.value);
                  handleCloseMenu();
                }}
                selected={currentVoiceId === type.value}
                sx={{ minHeight: 40 }}
              >
                <ItemIcon
                  className={"voice-icon"}
                  data-voice-icon-type={type.value}
                  sx={{
                    color: type.color,
                    mr: 2,
                    fontSize: 20,
                  }}
                />
                {type.title}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
};
