export function getFullTextFromBlocks(blocks) {
  let fullText = "";

  for (const block of blocks) {
    if (block.type === "paragraph") {
      for (const item of block.content) {
        fullText += item.text;
      }
    }
  }

  return fullText;
}
