import React, { useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  CardContent,
  Box,
  TextField,
  Button,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import {
  MAX_CHARS_PER_EPISODE,
  MIN_CHARS_PER_EPISODE,
  MAX_EPISODE_TITLE_LENGTH,
  MAX_EPISODE_SUBTITLE_LENGTH,
  ELEVEN_LABS_DEFAULT_VOICE_ID,
} from "../constants";
import UserContext from "../contexts/UserContext";
import { StyledCard } from "../atoms/StyledCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AudioBlockEditor from "./AudioBlockEditor/AudioBlockEditor";
import AudioBlockEditorWrapper from "./AudioBlockEditor/AudioBlockEditorWrapper";
import { getFullTextFromBlocks } from "./AudioBlockEditor/BlockParsingFunctions";

const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledForm = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const FormItem = styled(Box)({
  marginTop: "1rem",
  width: "100%",
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function AddEpisodeForm({
  onSubmit,
  addingEpisode,
  successTriggerAddEpisode,
}) {
  const { user } = useContext(UserContext);
  const usageCount = user.account.usage_count;
  const usageLimit = user.account.usage_limit;
  const usageLeft = usageLimit - usageCount;
  const maxCharsToAdd = Math.min(usageLeft, MAX_CHARS_PER_EPISODE);

  const [title, setTitle] = React.useState("");
  const [text, setText] = React.useState("");
  const [textTooShort, setTextTooShort] = React.useState(true);
  const [textTooLong, setTextTooLong] = React.useState(false);
  const [subtitle, setSubtitle] = React.useState("");
  const [summary, setSummary] = React.useState("");
  const [expandedOptions, setExpandedOptions] = React.useState(false);
  const [blocks, setBlocks] = React.useState([]);
  const [shouldEmptyAudioBlocks, setShouldEmptyAudioBlocks] =
    React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({
      blocks,
      title,
      text,
      voice_id: ELEVEN_LABS_DEFAULT_VOICE_ID,
      subtitle,
      summary,
    }); // Calling the passed function
  };

  const handleTitleChange = (e) => {
    if (e.target.value.length <= MAX_EPISODE_TITLE_LENGTH) {
      setTitle(e.target.value);
    }
  };

  const handleSubtitleChange = (e) => {
    if (e.target.value.length <= MAX_EPISODE_SUBTITLE_LENGTH) {
      setSubtitle(e.target.value);
    }
  };

  const handleExpandClick = () => {
    setExpandedOptions(!expandedOptions);
  };

  const handleBlocksChange = (blocks) => {
    setBlocks(blocks);

    const fullText = getFullTextFromBlocks(blocks);
    setText(fullText);

    const numChars = fullText.length;

    if (usageLeft < MIN_CHARS_PER_EPISODE || numChars > maxCharsToAdd) {
      setTextTooLong(true);
      setTextTooShort(false);
    } else if (numChars < MIN_CHARS_PER_EPISODE) {
      setTextTooShort(true);
      setTextTooLong(false);
    } else {
      setTextTooLong(false);
      setTextTooShort(false);
    }
  };

  useEffect(() => {
    // Emptying form after successful triggering of audio generation
    if (successTriggerAddEpisode) {
      setTitle("");
      setText("");
      setSubtitle("");
      setSummary("");
      setShouldEmptyAudioBlocks(true);
    }
  }, [successTriggerAddEpisode]);

  return (
    <StyledCard sx={{ borderWidth: "2px", borderColor: "primary.main" }}>
      <StyledCardContent>
        <StyledForm
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <FormItem>
            <TextField
              required
              id="episode-title-input"
              label="Title"
              type="text"
              fullWidth
              value={title}
              onChange={handleTitleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              helperText={`${title.length}/${MAX_EPISODE_TITLE_LENGTH}`}
            />
          </FormItem>
          <FormItem>
            <AudioBlockEditorWrapper
              textTooShort={textTooShort}
              textTooLong={textTooLong}
              textLength={text.length}
              maxCharsToAdd={maxCharsToAdd}
            >
              <AudioBlockEditor
                handleBlocksChange={handleBlocksChange}
                shouldEmptyAudioBlocks={shouldEmptyAudioBlocks}
                setShouldEmptyAudioBlocks={setShouldEmptyAudioBlocks}
              />
            </AudioBlockEditorWrapper>
          </FormItem>
          <FormItem>
            <Box
              onClick={handleExpandClick}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                padding: "0.2rem 0.5rem 0.2rem 1rem",
                color: "text.secondary",
                cursor: "pointer",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  flex: 1,
                  textAlign: "left",
                }}
              >
                Optional fields
              </Typography>
              <ExpandMore
                expand={expandedOptions}
                aria-expanded={expandedOptions}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Box>
            <Collapse in={expandedOptions} timeout="auto" unmountOnExit>
              <FormItem>
                <TextField
                  id="episode-subtitle-input"
                  label="Subtitle"
                  type="text"
                  fullWidth
                  value={subtitle}
                  onChange={handleSubtitleChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  helperText={`${subtitle.length}/${MAX_EPISODE_SUBTITLE_LENGTH}`}
                />
              </FormItem>
              <FormItem>
                <TextField
                  id="episode-summary-input"
                  label="Summary"
                  type="text"
                  multiline
                  fullWidth
                  minRows={4}
                  maxRows={20}
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                />
              </FormItem>
            </Collapse>
          </FormItem>
          <FormItem sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              type="submit"
              variant="contained"
              disabled={
                addingEpisode || textTooShort || textTooLong || title.length < 1
              }
            >
              {"Generate audio"}
            </Button>
          </FormItem>
        </StyledForm>
      </StyledCardContent>
    </StyledCard>
  );
}
