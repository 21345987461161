import { Box, Typography } from "@mui/material";
import { VoiceSelectionMenu } from "./VoiceSelectionMenu";
import { availableVoices } from "./VoiceBlock";

export default function DefaultVoiceSelector({
  handleSelectVoiceId,
  currentVoiceId,
}) {
  return (
    <Box
      sx={{
        marginLeft: 1,
        marginRight: 2,
        marginTop: 0,
        marginBottom: 1,
        height: "30px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Box sx={{ ml: 1, mr: 1 }}>
        <VoiceSelectionMenu
          handleSelectVoiceId={handleSelectVoiceId}
          currentVoiceId={currentVoiceId}
          availableVoices={availableVoices}
        />
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary">
          Default voice for new paragraphs
        </Typography>
      </Box>
    </Box>
  );
}
