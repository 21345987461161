// Env variables
export const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

// Layout variables
export const slimWidth = 700;
export const landingPageContainerWideWidth = 1100;
export const headerHeight = 64;
export const footerHeight = 200;
export const topMargin = 24;
export const bottomMargin = 64;
export const navbarDrawerWidth = 212;
export const swapNavbarWidth = 1100;

// Forms
export const BUG_REPORT_FORM_URL =
  "https://viktor-enzell.notion.site/13fc9f71f7f980ecb0baf871787ec6f8?pvs=105";
export const FEATURE_REQUEST_FORM_URL =
  "https://viktor-enzell.notion.site/13fc9f71f7f980d38476c10fde622c95?pvs=105";
export const CONTACT_FORM_URL = "mailto:podreaderai@gmail.com";

// Other variables
export const MIN_CHARS_PER_EPISODE = 20;
export const MAX_CHARS_PER_EPISODE = 90000;
export const MAX_CHARS_PER_SEGMENT = 1000;
export const MAX_EPISODE_TITLE_LENGTH = 100;
export const MAX_EPISODE_SUBTITLE_LENGTH = 200;

// Stripe constants
export const STRIPE_BASE_URL = "https://buy.stripe.com/";
export const STRIPE_STARTER_URL_SLUG = "3cseW2dG32qgfcYcMQ";
export const STRIPE_STANDARD_URL_SLUG = "00gbJQ59x3uk6GscMR";
export const STRIPE_PRO_URL_SLUG = "9AQ8xEeK72qg8OAeV0";

// ElevenLabs voice names
export const ELEVEN_LABS_DEFAULT_VOICE_ID = "iP95p4xoKVk53GoZ742B";
export const ELEVEN_LABS_VOICE_NAMES = {
  iP95p4xoKVk53GoZ742B: "🇺🇸 English Male",
  XrExE9yKIg1WjnnlVkGX: "🇺🇸 English Female",
  RSmgoqO778pcU4L1nBGw: "🇬🇧 English Male",
  x0u3EW21dbrORJzOq1m9: "🇸🇪 Swedish Male",
  "4xkUqaR9MYOJHoaC1Nak": "🇸🇪 Swedish Female",
};

// Feed language choices
export const FEED_LANGUAGE_CHOICES = {
  en: "English",
  sv: "Swedish",
};

// Apple Podcasts categories
export const APPLE_PODCAST_CATEGORIES = [
  "Arts",
  "Business",
  "Comedy",
  "Education",
  "Fiction",
  "Government",
  "History",
  "Health & Fitness",
  "Kids & Family",
  "Leisure",
  "Music",
  "News",
  "Religion & Spirituality",
  "Science",
  "Society & Culture",
  "Sports",
  "Technology",
  "True Crime",
  "TV & Film",
];

// Pricing options
export const PRICING_OPTIONS = {
  Starter: {
    title: "Starter",
    subtitle: "For trying out the product",
    bulletPoints: [
      "9,000 characters (~9 min audio)",
      "Built in podcast hosting",
      "Publish unlimited podcasts and episodes",
    ],
    price: 9,
  },
  Standard: {
    title: "Standard",
    subtitle: "Best value for getting started",
    bulletPoints: [
      "45,000 characters (~45 min audio)",
      "Built in podcast hosting",
      "Publish unlimited podcasts and episodes",
    ],
    price: 36,
  },
  Pro: {
    title: "Pro",
    subtitle: "Best price per minute of audio",
    bulletPoints: [
      "90,000 characters (~90 min audio)",
      "Built in podcast hosting",
      "Publish unlimited podcasts and episodes",
    ],
    price: 63,
  },
};
